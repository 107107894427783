<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 1200 1200"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(120.224 0.177)">
      <g transform="translate(0 0)">
        <path
          fill="currentColor"
          d="M-98.9,603.1c1.2-268.4,189.3-499.7,451.8-555.5C538.3,4,733.1,56.4,871.6,187     c101,89.2,164.9,213,179,346.9c22.8,168.3-33.3,337.7-152.1,459c-89.1,96.6-209.7,158.3-340.2,174     c-175.4,26.6-353.1-30.5-480.1-154.3C-17.7,923.5-78.7,803.1-93.8,673C-96.8,649.9-97.2,626.4-98.9,603.1z M-40,633.4     c5.6,125.4,59.4,243.7,150.3,330.2c204,202.3,533.1,201.9,736.7-0.8C957,856.8,1011.4,705.8,994.4,554     c-9.5-119.1-62.7-230.5-149.4-312.7C724,119,549.5,66,380.9,100.3C139.1,142.5-40,357.4-40,633.4z"
        />
      </g>
    </g>
    <path
      fill="currentColor"
      d="M1016.3,416.1H879.9V283c0-29.9-24.3-54.2-54.2-54.2h-17.1c-29.9,0-54.2,24.3-54.2,54.2v133.1H618  c-28.1,0-50.9,22.8-50.9,50.9v23.7c0,28.1,22.8,50.9,50.9,50.9h136.4v133.1c0,29.9,24.3,54.2,54.2,54.2h17.1  c29.9,0,54.2-24.3,54.2-54.2V541.6h136.4c28.1,0,50.9-22.8,50.9-50.9V467C1067.2,438.9,1044.4,416.1,1016.3,416.1z"
    />
    <path
      fill="currentColor"
      d="M773.2,998c0,0,0.1-0.1,0.1-0.1L483.6,827.6c-17.8-8.9-29-27.1-29-47v-54c4.6-5.3,9-10.9,13-16.6  c18.6-26.4,33-55.4,42.8-86.2c18.1-5.5,30.6-22.1,30.7-41.1v-57.6c0-12.2-5.3-23.9-14.4-32v-83.3c0,0,17.1-129.7-158.5-129.7  S209.8,409.8,209.8,409.8v83.3c-9.1,8.1-14.4,19.7-14.4,32v57.6c0.1,14.7,7.6,28.3,20,36.3c9.6,39.2,27.3,75.9,52.1,107.8v52.7  c-0.1,19.2-10.5,36.8-27.4,46l0.2-0.1l-116.4,52.6c49.9,51.1,152.1,231.5,284.2,252.2c31.7,5,67.5,2.2,104.2-5.5  c15,12.8,35,20.6,56.9,20.6h161.7c46.7,0,84.5-35.4,84.5-79C815.3,1037.1,798.4,1011.6,773.2,998z"
    />
  </svg>
</template>
